<template>
  <div class="modal fade" :id="this.$attrs?.id ?? 'vessel-list' "  tabindex="1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5>{{(related_party ? related_party : '') | toUpper}}</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row align-items-center">
            <div class="col-6 align-items-center font-weight-bold" v-for="vessel in vessels">
              <font-awesome-icon icon="ship" size="2x" /> {{vessel.vessel | toUpper}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {

    vessels: {
      type: Array | Object,
      default: null,
    },

    related_party: {
      type: String | Number,
      default: '',
    }

  },
}
</script>

<style scoped>

</style>

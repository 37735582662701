<template>
  <div class="modal fade" id="relatedPartiesModal" tabindex="-1" role="dialog" aria-labelledby="relatedPartiesModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mr-5" id="exampleModalLongTitle">Select Related Parties</h5>
          <select name="" id="" class="form-control form-control-sm w-auto"
                  v-model="selectedPartyId"
                  @change="relatedPartySelectionHandler(selectedPartyId)">
            <option :value="null" disabled>—— Select Vessel ——</option>
            <option v-for="relatedParty in relatedParties" :key="relatedParty.id" :value="relatedParty.id">
              {{relatedParty.party_name}}
            </option>
          </select>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" @click="reset">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-3" v-for="vessel in vesselKeys" :key="vessel.id">
                <div class="form-check">
                  <input type="checkbox"
                         class="form-check-input mt-0"
                         :id="vessel.name"
                         :value="vessel.id"
                         @change="selectedVesselChecker"
                         v-model="selectedVesselIds"
                  >
                  <label class="form-check-label" :for="vessel.name">{{ vessel.name }}</label>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="e-btn e-btn-md e-btn-green px-5" data-dismiss="modal" @click="relatedPartySelected">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RelatedPartiesModal",
  props: ['reportVesselId'],
  data() {
    return {
      selectedVesselIds: [],
      selectedPartyId: null,
      initialSelectedVessel:[],
      initialSelectedParty:null,
    }
  },
  methods: {
    ...mapActions(['getRelatedParties','getVesselKeys', 'getRelatedPartyVessels']),
    relatedPartySelected (){
      this.$emit('selected-values', {
        'selectedParty': this.selectedPartyId,
        'selectedVessels': this.selectedVesselIds,
      })
      // this.reset();
    },
    async relatedPartySelectionHandler(selectedParty){
      let params = { related_party_id : selectedParty, vessel_id: this.reportVesselId }
      await this.getRelatedPartyVessels(params)
      this.initialSelectedVessel=this.relatedPartyVessels;
      this.selectedVesselIds = this.relatedPartyVessels;
      this.initialSelectedParty=selectedParty;
    },
    selectedVesselChecker(event){
      let vesselId=event.target.value;
      if (this.initialSelectedVessel.length == this.selectedVesselIds.length) {
        var stringedNum = JSON.stringify(this.initialSelectedVessel.sort())
        var stringedNum2 = JSON.stringify(this.selectedVesselIds.sort())
        if (stringedNum === stringedNum2) {
          this.selectedPartyId = this.initialSelectedParty;
        } else {
          this.selectedPartyId = 8
        }
      } else {
        if (this.selectedVesselIds.length === this.vesselKeys.length) {
          this.selectedPartyId = 1;
        } else {
          this.selectedPartyId = 8
        }
      }

    },
    reset(){
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  },
  mounted() {
    this.getRelatedParties()
    this.getVesselKeys()
  },

  computed: {
    ...mapGetters(['relatedParties','vesselKeys','relatedPartyVessels'])
  }
}
</script>

<style scoped>

</style>

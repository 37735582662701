<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false" :id="this.$attrs?.id ?? 'ci-modal' ">
    <div class="modal-dialog modal-xl modal-dialog-centered" >
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CIModal'
}
</script>
<style scoped>

</style>
